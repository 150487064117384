<template>
  <div :id="'pdf-container-' + this.keyIndex" class="pdf-container">

  </div>
</template>

<script>

import {inject, ref} from 'vue';
import PSPDFKit from 'pspdfkit';

export default {
  name: 'PSPDFKitContainer',
  inject: ['pspdfkitlkey'],
  props: {
    pdfFile: {
      type: String,
      required: true,
    },
    keyIndex: {
      type: String,
      required: false,
    },
  },
  methods: {
    async loadPSPDFKit() {
      PSPDFKit.unload('#pdf-container-' + this.keyIndex);
      const pspdfkitlkey = inject('pspdfkitlkey');
      const fullScreenButton = {
        type: "custom",
        title: 'FullScreen',
        className: "pspdfkit-fullscreen",
        name: "fullScreen",
        icon:'/img/fullscreen.svg',
        onPress: async () => {
          this.requestFullscreen(document.getElementById('pdf-container-' + this.keyIndex));
        },
      };
      // const initialViewState = new PSPDFKit.ViewState({
      //   readOnly: true,//!this.$store.state.edit,
      //   enableAnnotationToolbar: false,//!this.$store.state.edit,
      // });
      let defaultToolbarItems = [];
      if (!this.$store.state.edit) {
        PSPDFKit.defaultToolbarItems.map((value) => {
          if (!['document-editor', 'document-crop'].includes(value.type)) {
            defaultToolbarItems.push(value);
          }
        });
      } else {
        defaultToolbarItems = PSPDFKit.defaultToolbarItems;
      }
      return PSPDFKit.load({
        document: this.pdfFile,
        container: '#pdf-container-' + this.keyIndex,
        toolbarItems: [...defaultToolbarItems, fullScreenButton],
        licenseKey: pspdfkitlkey,
        //initialViewState,
      });
    },
    requestFullscreen(element) {
      if (element.classList.contains('full-screen-mode')) {
        element.classList.remove('full-screen-mode');
      } else {
        element.classList.add('full-screen-mode');
      }
      // if (document.fullscreenElement !== null) {
      //   document.exitFullscreen();
      // } else if (element.requestFullscreen) {
      //   element.requestFullscreen();
      // } else if (element.mozRequestFullScreen) {
      //   element.mozRequestFullScreen();
      // } else if (element.webkitRequestFullscreen) {
      //   element.webkitRequestFullscreen();
      // } else if (element.msRequestFullscreen) {
      //   element.msRequestFullscreen();
      // }
    },
  },
  mounted() {
    this.loadPSPDFKit().then((instance) => {
      console.log("pdfcontainerloaded");
      this.$emit('loaded', instance);
      // instance.addEventListener("annotations.create", function (createdAnnotations) {
      //
      // });
    });
  },
  watch: {
    pdfFile(val) {
      if (val) {
        this.loadPSPDFKit();
      }
    }
  }
};
</script>

<style lang="scss">
.pdf-container{
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  aspect-ratio: 1/1.41;
}
.pspdfkit-fullscreen[title = FullScreen]{
  color: red;
}
.full-screen-mode{
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10; /* Make sure it's on top of other elements */
  justify-content: center;
  align-items: center;
}
</style>
